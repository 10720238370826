import React from "react"
import styled from "styled-components"
import Section from "@hoffman/shared/src/components/Section"

interface NavSidebarMenuProps {
  handleShowSideMenu: () => void
}
const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: ${({ theme }) => theme.colors.background};
  opacity: 0.5;
  cursor: pointer;
`

const NavbarOverlay = ({
  show,
  handleShowSideMenu,
  bgImg,
  links,
  cta,
}: NavSidebarMenuProps) => {
  return (
    <Section themeName="DARK">
      <StyledOverlay onClick={handleShowSideMenu} />
    </Section>
  )
}

export default NavbarOverlay
