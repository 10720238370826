import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {themes} from '../../theme';
import {RightArrowBig, LinklessLeftArrow} from '../Arrow/arrow';

export const Cta = ({color, link, text, tar, hover, alt}) => {
  return (
    <CtaContainer
      color={color}
      href={link}
      target={tar ? '_blank' : null}
      rel="noopener noreferrer"
      hover={hover}
    >
      {text}
    </CtaContainer>
  );
};

export const ArrowCta = ({color, link, text, hover, tar, alt, clickHandler}) => {
  const theme = useContext(ThemeContext);
  return (
    <div>
      <ArrowCtaContainer
        color={color}
        href={link}
        target={tar ? '_blank' : null}
        rel="noopener noreferrer"
        hover={hover}
        onClick={clickHandler}
      >
        <ArrowSpan>{text}</ArrowSpan>
        <RightArrowBig size="30" />
      </ArrowCtaContainer>
    </div>
  );
};

export const BoxlessArrowCta = ({color, link, text, hover, tar, alt}) => {
  const theme = useContext(ThemeContext);
  return (
    <div>
      <BoxlessArrowCtaContainer
        color={color}
        href={link}
        target={tar ? '_blank' : null}
        rel="noopener noreferrer"
        hover={hover}
      >
        <LinklessLeftArrow size="20" />
        <BoxlessArrowSpan>{text}</BoxlessArrowSpan>
      </BoxlessArrowCtaContainer>
    </div>
  );
};

const CtaContainer = styled.a`
  display: inline-block;
  border: ${({theme}) => theme.colors.text} 1px solid;
  color: ${({theme}) => theme.colors.text};
  font-family: ${({theme}) => theme.fontFaces.default};
  font-size: ${({theme}) => theme.fontSizes[1]}px;
  padding: ${({theme}) =>
    `${theme.spaces[2] + theme.spaces[1]}px ${theme.spaces[4]}px`};
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: ${({theme}) => theme.letterSpacings[5]}px;
  transition: background-color ease-in 0.15s;
  font-weight: ${({theme}) => theme.fontWeights.light};
  &:hover {
    background-color: ${({theme}) => theme.colors.text};
    color: ${({theme, alt}) =>
      alt ? theme.colors.backgroundAlt : theme.colors.background};
  }

  @media screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    &:hover {
      background-color: initial;
      color: initial;
    }
  }
`;

const ArrowCtaContainer = styled.a`
  display: inline-block;
  border: ${({theme}) => theme.colors.text} 1px solid;
  color: ${({theme}) => theme.colors.text};
  font-family: ${({theme}) => theme.fontFaces.default};
  font-size: ${({theme}) => theme.fontSizes[1]}px;
  padding: ${({theme}) =>
    `${theme.spaces[2] + theme.spaces[1]}px ${theme.spaces[3]}px`};
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: ${({theme}) => theme.letterSpacings[5]}px;

  font-weight: ${({theme}) => theme.fontWeights.light};

  svg {
    transition: transform ease-in 0.3s;

    path {
      fill: ${({theme}) => theme.colors.text};
    }

    g {
      fill: ${({theme}) => theme.colors.text};
    }
  }
  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  @media screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    font-size: ${({theme}) => theme.fontSizes[0]}px;
    &:hover {
      svg {
        transform: none;
      }
    }
  }
`;

const ArrowSpan = styled.span`
  padding-right: 30px;
`;
const BoxlessArrowCtaContainer = styled.a`
  color: ${({theme}) => theme.colors.text};
  font-family: ${({theme}) => theme.fontFaces.default};
  font-size: ${({theme}) => theme.fontSizes[1]}px;
  text-decoration: none;
  font-weight: ${({theme}) => theme.fontWeights.light};

  svg {
    transition: transform ease-in 0.3s;
  }
  &:hover {
    svg {
      transform: translateX(-5px);
    }
  }
  @media screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    &:hover {
      svg {
        transform: none;
      }
    }
  }
`;

const BoxlessArrowSpan = styled.span`
  padding-left: 20px;
`;
