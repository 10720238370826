import React, {ReactNode} from 'react';
import {AmarisThemeName} from '@hoffman/shared/src/theme';
import styled from 'styled-components';

/**
 * Adds background to all children elements
 * within SectionBackgroundGroup.
 */

/**
 * Plug in one of the following into the px prop:
 * None - Full width elements
 * Header - Header
 * Standard - Default. Footer, non-full width image blocks.
 * Narrow - Floor plan, Floor plan indiv., Block quotes
 * Narrowest - Content blocks
 */

/**
 * Plug in one of the following into the py prop:
 * None - No padding
 * Shorter - Individual floor plan.
 * Short - Floor plans page
 * Footer - Vertical padding on footer
 * Standard - Default. Content blocks.
 * Tall - Additional padding
 */

type px = 'none' | 'header' | 'standard' | 'narrow' | 'narrowest';
type py = 'none' | 'shorter' | 'short' | 'footer' | 'standard' | 'tall';

interface SectionPaddingProps {
  children: ReactNode;
  px?: px; // Padding left/right
  py?: py; // Padding top/bottom
  theme?: AmarisThemeName;
}

const StyledDiv = styled.div<SectionPaddingProps>`
  ${({px, theme}) =>
    px === 'none'
      ? `padding-left: ${theme.spaces[0]}px;padding-right: ${theme.spaces[0]}px;`
      : px === 'narrow'
      ? `padding-left: ${theme.spaces[9]}px;padding-right: ${theme.spaces[9]}px;`
      : px === 'narrowest'
      ? `padding-left: ${theme.spaces[7] + theme.spaces[9]}px;padding-right: ${
          theme.spaces[7] + theme.spaces[9]
        }px;`
      : px === 'header'
      ? `padding-left: ${theme.spaces[6]}px;padding-right: ${theme.spaces[6]}px;`
      : `padding-left: ${theme.spaces[7]}px;padding-right: ${theme.spaces[7]}px;`}

  ${({py, theme}) =>
    py === 'none'
      ? `padding-top: ${theme.spaces[0]}px;padding-bottom: ${theme.spaces[0]}px;`
      : py === 'shorter'
      ? `padding-top: ${theme.spaces[4]}px;padding-bottom: ${theme.spaces[4]}px;`
      : py === 'short'
      ? `padding-top: ${theme.spaces[5]}px;padding-bottom: ${theme.spaces[5]}px;`
      : py === 'footer'
      ? `padding-top: ${theme.spaces[6]}px;padding-bottom: ${theme.spaces[6]}px;`
      : py === 'tall'
      ? `padding-top: ${theme.spaces[9]}px;padding-bottom: ${theme.spaces[9]}px;`
      : `padding-top: ${theme.spaces[7]}px;padding-bottom: ${theme.spaces[7]}px;`}

  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    ${({px, theme}) =>
      px === 'none'
        ? `padding-left: 0;padding-right:0;`
        : `padding-left: ${theme.spaces[3]}px;padding-right: ${theme.spaces[3]}px;`}

    ${({py, theme}) =>
      py === 'none'
        ? `padding-top: ${theme.spaces[0]}px;padding-bottom: ${theme.spaces[0]}px;`
        : py === 'shorter'
        ? `padding-top: ${theme.spaces[3]}px;padding-bottom: ${theme.spaces[3]}px;`
        : py === 'short'
        ? `padding-top: ${theme.spaces[3]}px;padding-bottom: ${theme.spaces[3]}px;`
        : py === 'footer'
        ? `padding-top: ${theme.spaces[4]}px;padding-bottom: ${theme.spaces[4]}px;`
        : py === 'tall'
        ? `padding-top: ${theme.spaces[5]}px;padding-bottom: ${theme.spaces[5]}px;`
        : `padding-top: ${theme.spaces[4]}px;padding-bottom: ${theme.spaces[4]}px;`}
  }
`;

const SectionPadding = ({
  children,
  px = 'standard',
  py = 'standard',
}: SectionPaddingProps) => {
  return (
    <StyledDiv px={px} py={py}>
      {children}
    </StyledDiv>
  );
};

export default SectionPadding;
