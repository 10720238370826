/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

function SEO({description, lang, meta, title}) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title ? `%s | ${title}` : null}
      meta={[
        {
          name: `description`,
          content: description || 'Website for Amaris DC',
        },
        {
          property: `og:title`,
          content: title || 'Amaris DC',
        },
        {
          property: `og:description`,
          content: description || 'Website for Amaris DC',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'hzdg',
        },
        {
          name: `twitter:title`,
          content: title || 'Amaris DC',
        },
        {
          name: `twitter:description`,
          content: description || 'Website for Amaris DC',
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
