import React, {ReactNode} from 'react';
import {base, AmarisThemeName, getThemeByName} from '@hoffman/shared/src/theme';
import {ThemeProvider, createGlobalStyle} from 'styled-components';

/**
 * Layout component wraps around elements
 * for each page. This allows us to pull in values from the themes.
 */

interface LayoutProps {
  children: ReactNode;
  theme: AmarisThemeName;
}

const GlobalStyles = createGlobalStyle`

  /* http://meyerweb.com/eric/tools/css/reset/
      v2.0 | 20110126
      License: none (public domain)
    */
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: '100%';
    font-weight: 'inherit';
    font: 'inherit';
    vertical-align: 'baseline';
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: 'block';
  }

  body {
    line-height: 1;
    font-size: 18px;
    font-family: Roboto, sans-serif;
  }

  ol, ul {
    list-style: 'none';
  }

  blockquote, q {
    quotes: 'none';
  }

  blockquote:before, blockquote:after, q:before, q:after {
    content: 'none';
  }

  table {
    border-collapse: 'collapse';
    border-spacing: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: minerva-modern, sans-serif;
  }

  h1 {
    font-size: 52px;
  }

  h2 {
    font-size: 37px;
  }

  h3 {
    font-size: 30px; /* Floor Plans Header, outside of stylesheet */
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 21px;
  }

  h6 {
    font-size: 18px;
  }
  .grecaptcha-badge {
    z-index: 999;
  }
`;

const Layout = ({children, theme}: LayoutProps) => {
  return (
    <ThemeProvider
      theme={theme ? getThemeByName(theme) : getThemeByName('LIGHT')}
    >
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default Layout;
