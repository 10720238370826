import React, {ReactNode} from 'react';
import PropTypes from 'prop-types';
import Layout from '@hoffman/shared/src/layouts/Layout';
import styled from 'styled-components';

interface BaseLayoutProps {
  children: ReactNode;
}

const BaseLayout = ({children}: BaseLayoutProps) => {

  if ( typeof window !== `undefined` && window.location.search) {
    let urlParams = new URLSearchParams(window.location.search);
    document.cookie = "utm_source=" + (urlParams.get('utm_source') ? urlParams.get('utm_source') : 'untagged');
    document.cookie = "utm_medium=" + (urlParams.get('utm_medium') ? urlParams.get('utm_medium') : 'untagged');
    document.cookie = "utm_campaign=" + (urlParams.get('utm_campaign') ? urlParams.get('utm_campaign') : 'untagged');
  }
 
  return (
    <Layout theme="LIGHT">
      <div>
        <main>{children}</main>
      </div>
    </Layout>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
