import React, { ReactNode, forwardRef, ComponentPropsWithRef } from "react"
import { AmarisThemeName, getThemeByName } from "@hoffman/shared/src/theme"
import { Section as HzSection } from "@hzdg/sectioning"
import styled, { ThemeProvider } from "styled-components"

/**
 * Section component allows us to plug in a different theme on the
 * block/element level.
 * Note, this should only be used to apply a new instance of <ThemeProvider>/
 * add a new theme.
 */

interface SectionProps extends ComponentPropsWithRef<"div"> {
  children: ReactNode
  themeName?: AmarisThemeName
}

const SectionElement = styled.div`
  margin: 0;
  padding: 0;
`

const Section = forwardRef(function Section(
  { children, themeName }: SectionProps,
  forwardedRef?: SectionProps["ref"]
) {
  const sectionElement = (
    <SectionElement as={HzSection} ref={forwardedRef}>
      {children}
    </SectionElement>
  )
  return themeName ? (
    <ThemeProvider theme={getThemeByName(themeName)}>
      {sectionElement}
    </ThemeProvider>
  ) : (
    sectionElement
  )
})

export default Section
