import React, {useState} from 'react';

interface LogoProps {
  color: string;
  size: string;
}

export const HamburgerLogo = () => {
  return (
    <>
      <svg width="45px" height="9px" viewBox="0 0 45 9" version="1.1">
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        >
          <g
            transform="translate(-1341.000000, -43.000000)"
            stroke="#414448"
            strokeWidth="2"
          >
            <g transform="translate(1129.000000, 34.000000)" id="Group-27-Copy">
              <g>
                <g>
                  <g transform="translate(140.000000, 0.000000)">
                    <g transform="translate(73.000000, 10.000000)">
                      <line
                        className="line-1"
                        x1="0.5"
                        y1="7"
                        x2="21.5"
                        y2="7"
                        id="Line-Copy-2"
                      ></line>
                      <line
                        className="line-2"
                        x1="0.5"
                        y1="0.5"
                        x2="42.5"
                        y2="0.5"
                      ></line>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
