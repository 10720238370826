import * as colors from './colors';

/*** Base theme, carried across all themes. */
export const base = {
  boxShadow: '0 0 73px 0 rgba(0,0,0,0.23)',
  //               [0    1     2    3  4  5]
  letterSpacings: [-1, -0.5, -0.25, 0, 1, 2],

  //      [0  1  2   3   4   5   6   7    8    9]
  spaces: [0, 4, 8, 16, 32, 48, 64, 88, 104, 128],

  lineHeights: {
    body: 1.5,
    heading: 1.25,
    headingSecondary: 1.125,
  },

  fontFaces: {
    heading: 'minerva-modern,sans-serif',
    default: 'roboto,sans-serif',
  },

  //         [ 0   1   2   3   4   5   6]
  fontSizes: [14, 16, 18, 21, 24, 37, 52],

  fontWeights: {
    medium: 500,
    regular: 400,
    light: 300,
  },

  // Using this to pull in colors for Style Guide
  baseColors: {
    ...colors,
  },

  breakpx: [767, 1150],
  breakpoints: ['768px', '1150px'],
};

/*** Themes, determined at block level. */
export const themes = {
  LIGHT: {
    ...base,
    colors: {
      text: colors.DARK_GRAY,
      textAlt: colors.STONE,
      background: colors.CREAM,
      backgroundAlt: colors.WHITE,
      heading: colors.DARK_GRAY,
      border: colors.LIGHT_GRAY,
      footerBorder: colors.SAND,
    },
  },
  DARK: {
    ...base,
    colors: {
      text: colors.WHITE,
      textAlt: colors.WHITE,
      background: colors.DARK_GRAY,
      backgroundAlt: colors.DARK_GRAY,
      heading: colors.WHITE,
      border: colors.CREAM,
      footerBorder: colors.CREAM,
    },
  },
};

export const getThemeByName = (name: AmarisThemeName): AmarisThemeConfig => {
  return themes[name];
};

/** Type is either LIGHT or DARK. */
export type AmarisThemeName = keyof typeof themes;

export type AmarisColor = keyof typeof base['baseColors'];

/* Given an arbitrarily deeply nested type `T`, returns a deeply writable,
 * partial, and widened type.
 *
 * - 'widened' means literal types are asserted as primitive types
 *   (i.e., `'#000000'` becomes `'string'`)
 * - 'writable' means `readonly` is removed from all fields
 * - 'partial' means all fields are made optional
 * - 'deep' means these transformations are applied recursively
 */
type DeepPartialWritableWidened<T> = T extends Record<string, unknown>
  ? {-readonly [P in keyof T]?: DeepPartialWritableWidened<T[P]>}
  : T extends ReadonlyArray<infer U>
  ? Array<DeepPartialWritableWidened<U>>
  : T extends string
  ? string
  : T extends number
  ? number
  : T extends boolean
  ? boolean
  : T;

export type AmarisTheme = typeof base;

export type AmarisThemeConfig = DeepPartialWritableWidened<AmarisTheme>;
