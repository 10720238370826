import React, {Component} from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
// import {greaterBreakpoint} from '../../theme';

import './Style.css';
import {HamburgerLogo} from '../HamburgerLogo/hamburgerLogo';
import {Logo} from '../Logo/logo';
import NavSidebarMenu from '@hoffman/shared/src/components/NavbarSideMenu';
import NavbarOverlay from '@hoffman/shared/src/components/NavbarOverlay';
import SectionPadding from '@hoffman/shared/src/components/SectionPadding';
import {Link} from 'gatsby';

interface NavbarProps {}

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevScrollpos:
        typeof window !== 'undefined' && window ? window.pageYOffset : null,
      showSideMenu: false,
      visible: true,
      isScrollUp: false,
    };
    this.navRef = React.createRef();
  }

  // Add event listener when the component is mount.
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  checkIfScrollUp = () => {
    const {prevScrollpos} = this.state;
    const currentScrollPos =
      typeof window !== 'undefined' && window
        ? window.pageYOffset
        : document
        ? document.documentElement.scrollTop
        : null;

    const visible = currentScrollPos <= 0 || prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      isScrollUp: prevScrollpos > currentScrollPos,
      visible,
    });
  };

  // Hide or show the menu.
  handleScroll = () => {
    this.checkIfScrollUp();
  };

  isScrolledPastTop = () => {
    if (!this.navRef.current) return;
    const currentScrollPos =
      typeof window !== 'undefined' && window
        ? window.pageYOffset
        : document
        ? document.documentElement.scrollTop
        : null;
    const bottomEdge = this.navRef.current.getBoundingClientRect();
    return bottomEdge.top < -114.36;
  };

  handleShowSideMenu = () => {
    this.setState({showSideMenu: !this.state.showSideMenu});
  };
  render() {
    return (
      <StyledNavContainer ref={this.navRef}>
        <StyledNav
          className={classnames('navbar', {
            // This was implemented to match the prototype better
            //
            'navbar--fixed':
              (!this.state.isScrollUp && this.state.prevScrollpos > 114.36) ||
              (this.state.isScrollUp && this.state.prevScrollpos > 0),
            'navbar--hidden': !this.state.visible || this.props.forceHidden,
            'navbar--top-alt': this.props.isHome,
          })}
        >
          <SectionPadding py="shorter">
            <Container>
              <ContentContainer>
                <LogoContainer to="/">
                  <Logo color="#414448" size="300" />
                </LogoContainer>
                <RightContainer>
                  {/* This is named right side because we still
              haven't figured out what these 2 components
              will be doing */}
                  <RightSide>
                    <RightSideLink href="/contact">Contact</RightSideLink>
                  </RightSide>
                  <RightSide onClick={this.handleShowSideMenu}>
                    <RightSideSpan>Menu</RightSideSpan> <HamburgerLogo />
                  </RightSide>
                </RightContainer>
              </ContentContainer>
            </Container>
            <StyledSidebarContainer>
              {this.state.showSideMenu && (
                <NavbarOverlay handleShowSideMenu={this.handleShowSideMenu} />
              )}
              <NavSidebarMenu
                handleShowSideMenu={this.handleShowSideMenu}
                show={this.state.showSideMenu}
                bgImg={this.props.fluid}
                links={this.props.links}
                cta={this.props.cta}
              />
            </StyledSidebarContainer>
          </SectionPadding>
        </StyledNav>
      </StyledNavContainer>
    );
  }
}

// Need this to see if nav is scrolled out of viewport
const StyledNavContainer = styled.div`
  height: 114.36px;
  position: absolute;
  width: 100%;
`;

const StyledNav = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  transition: top 0.6s ease-in-out, background-color 0.2s ease-in,
    color 1s ease-in, fill 0.6s ease-in;
  background-color: #fff;

  @media only screen and (min-width: ${({theme}) => theme.breakpx[0] + 1}px) {
    &.navbar--fixed {
      background-color: ${({theme}) => theme.colors.background};
    }
    &.navbar--hidden {
      top: -138px;
    }
  }

  @media only screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    position: absolute;
    background-color: transparent;
  }
`;

const StyledSidebarContainer = styled.nav`
  position: relative;
`;

const Container = styled.div`
  margin: 0px;
  font-family: Roboto;
  font-weight: 100;
  color: #414448;
  background-color: transparent;
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoContainer = styled(Link)`
  cursor: pointer;
  @media only screen and (max-width: ${({theme}) => theme.breakpoints[1]}) {
    svg {
      width: 250px;
    }
  }

  @media only screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    svg {
      width: 116px;
    }
    .navbar--top-alt & {
      color: ${({theme}) => theme.colors.backgroundAlt};

      svg path,
      svg g,
      svg polygon,
      svg polyline {
        fill: ${({theme}) => theme.colors.backgroundAlt};
      }

      svg line {
        stroke: ${({theme}) => theme.colors.backgroundAlt};
      }
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 3px;
`;
const Icon = styled.div`
  font-size: 35px;
  text-transform: uppercase;
  letter-spacing: 10px;
`;

const RightSide = styled.span`
  font-size: 15px;
  padding: 0 10px;
  cursor: pointer;

  &:last-child {
    padding-right: 0;
  }

  svg line {
    transition: transform 0.2s ease-in;
  }

  @media only screen and (min-width: ${({theme}) => theme.breakpx[0] + 1}px) {
    svg {
      &:hover {
        line {
          transform: scaleX(0.5);
        }

        #Line-Copy-2 {
          transform: scaleX(2);
        }
      }
    }
  }

  @media only screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    .navbar--top-alt & {
      color: ${({theme}) => theme.colors.backgroundAlt};

      svg path,
      svg g,
      svg polygon,
      svg polyline {
        fill: ${({theme}) => theme.colors.backgroundAlt};
      }

      svg line {
        stroke: ${({theme}) => theme.colors.backgroundAlt};
      }
    }
  }
`;

const RightSideLink = styled.a`
  text-decoration: none;
  color: ${({theme}) => theme.colors.textAlt};
  &:hover {
    text-decoration: none;
    color: ${({theme}) => theme.colors.textAlt};
  }

  @media only screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    .navbar--top-alt & {
      color: ${({theme}) => theme.colors.backgroundAlt};
    }
  }
`;

const RightSideSpan = styled.span`
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
