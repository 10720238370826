import React from "react"

interface CloseIconProps {
  color: string
  size: string
}

const CloseIcon = ({ color, size }: CloseIconProps) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="10 10 15 15"
      version="1.1"
    >
      <defs>
        <filter
          x="-53.4%"
          y="-16.9%"
          width="206.8%"
          height="133.7%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset
            dx="0"
            dy="0"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="36.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.226617133 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g
        id="HOME"
        stroke="none"
        strokeWidth="1"
        fill={color}
        fillRule="evenodd"
        strokeLinecap="square"
        transform="translate(-21, -25)"
      >
        <g
          id="Home_Phase-1-v2-OPEN-NAV"
          transform="translate(-1353.000000, -4.000000)"
          stroke="#414448"
        >
          <g
            id="Group"
            filter="url(#filter-1)"
            transform="translate(1176.000000, 0.000000)"
          >
            <g id="Close-X" transform="translate(141.000000, 33.000000)">
              <g id="Close_x" transform="translate(68.750000, 7.000000)">
                <line
                  x1="11"
                  y1="0.75"
                  x2="0.5"
                  y2="11.75"
                  id="Line-Copy-2"
                ></line>
                <line
                  x1="1.25"
                  y1="0.75"
                  x2="10.75"
                  y2="11.75"
                  id="Line-Copy-2"
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CloseIcon
