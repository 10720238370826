import React from 'react';
import {useState} from 'react';

interface ArrowProps {
  color?: string;
  link?: string;
  horizontalSpacing?: string;
  size: string;
  hoverColor?: string;
}

export const LinklessLeftArrow = ({
  color,
  horizontalSpacing,
  size,
  hoverColor,
}: ArrowProps) => {
  const [isShown, setIsShown] = useState(false);
  return (
    <span style={{padding: horizontalSpacing}}>
      <svg
        x="0px"
        y="0px"
        width={size}
        viewBox="0 0 512 395"
        fill={isShown ? hoverColor : color}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <g>
          <g>
            <path
              d="M492,236H68.442l70.164-69.824c7.829-7.792,7.859-20.455,0.067-28.284c-7.792-7.83-20.456-7.859-28.285-0.068
          l-104.504,104c-0.007,0.006-0.012,0.013-0.018,0.019c-7.809,7.792-7.834,20.496-0.002,28.314c0.007,0.006,0.012,0.013,0.018,0.019
          l104.504,104c7.828,7.79,20.492,7.763,28.285-0.068c7.792-7.829,7.762-20.492-0.067-28.284L68.442,276H492
          c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export const LeftArrow = ({
  color,
  link,
  horizontalSpacing,
  size,
  hoverColor,
}: ArrowProps) => {
  const [isShown, setIsShown] = useState(false);
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <span style={{padding: horizontalSpacing}}>
        <svg
          x="0px"
          y="0px"
          width={size}
          viewBox="0 0 512 395"
          fill={isShown ? hoverColor : color}
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          <g>
            <g>
              <path
                d="M492,236H68.442l70.164-69.824c7.829-7.792,7.859-20.455,0.067-28.284c-7.792-7.83-20.456-7.859-28.285-0.068
          l-104.504,104c-0.007,0.006-0.012,0.013-0.018,0.019c-7.809,7.792-7.834,20.496-0.002,28.314c0.007,0.006,0.012,0.013,0.018,0.019
          l104.504,104c7.828,7.79,20.492,7.763,28.285-0.068c7.792-7.829,7.762-20.492-0.067-28.284L68.442,276H492
          c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"
              />
            </g>
          </g>
        </svg>
      </span>
    </a>
  );
};

export const RightArrow = ({
  color,
  link,
  horizontalSpacing,
  size,
  hoverColor,
}: ArrowProps) => {
  const [isShown, setIsShown] = useState(false);
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <span style={{padding: horizontalSpacing}}>
        <svg
          x="0px"
          y="0px"
          width={size}
          viewBox="0 0 512 395"
          fill={isShown ? hoverColor : color}
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          <g>
            <g>
              <path
                d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
          c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
          l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
          c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
              />
            </g>
          </g>
        </svg>
      </span>
    </a>
  );
};

export const RightArrowBig = ({
  color,
  link,
  horizontalSpacing,
  size,
  hoverColor,
}: ArrowProps) => {
  const [isShown, setIsShown] = useState(false);
  return (
    <svg
      width={size}
      height="10px"
      viewBox="0 0 29 10"
      version="1.1"
      fill={isShown ? hoverColor : color}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="FP-Card-/-Mobile"
          transform="translate(-158.000000, -232.000000)"
          fill="#231F20"
        >
          <g id="FP-Card">
            <g>
              <g id="Button-/CTA" transform="translate(61.000000, 220.000000)">
                <g id="CTA-Button">
                  <g
                    id="Arrows-/-Black"
                    transform="translate(97.000000, 12.000000)"
                  >
                    <polygon
                      id="Fill-1"
                      points="23.2896604 0 22.7296604 0.558421053 26.7569057 4.57052632 3.37842961e-14 4.57052632 3.37842961e-14 5.36 26.6422642 5.36 22.7296604 9.25789474 23.2896604 9.81631579 28.2166038 4.90842105"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
