import React from "react"

interface LogoProps {
  color: string
  size: string
}

export const Logo = ({ color, size }: LogoProps) => {
  return (
    <>
      <svg width={size} viewBox="0 0 397 64">
        <defs>
          <polygon points="0 63.8985447 396.54574 63.8985447 396.54574 -0.000191721133 0 -0.000191721133" />
        </defs>
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g transform="translate(-86.000000, -36.000000)">
            <g transform="translate(86.000000, 36.000000)">
              <g>
                <g>
                  <g>
                    <path
                      d="M130.51146,63.6232331 L128.761224,28.6705534 C128.593263,25.1524706 128.42721,19.7095076 128.42721,17.6139956 L128.259248,17.6139956 C127.427075,19.2896383 125.008811,23.5611852 122.25844,27.5815773 L109.417017,46.8437996 L107.048378,49.7848017 L91.4909404,27.0773508 C89.2387288,23.5611852 86.8204644,19.4564357 86.1543442,17.9495076 L85.9863827,17.9495076 C85.9863827,19.7095076 85.9863827,25.2368279 85.9024019,28.4193987 L84.9022673,63.6232331 L79.9817577,63.6232331 L81.5659404,10.2442353 L81.8560558,2.02898475 L101.496104,31.7687669 C104.246474,36.1227538 106.582666,39.8095512 107.330859,41.3164793 L107.49882,41.3164793 C108.582936,39.3896819 110.999291,35.789159 113.669498,31.6019695 L132.813296,2.02898475 L133.345811,10.2442353 L135.932037,63.6232331 L130.51146,63.6232331 Z"
                      fill={color}
                    ></path>

                    <g></g>
                    <polygon
                      fill={color}
                      points="322.938505 63.6243834 328.525135 63.6243834 328.525135 2.57654031 322.938505 2.57654031"
                    ></polygon>
                    <path
                      d="M377.450995,63.8985447 C371.698312,63.8985447 366.277735,61.9736645 362.361177,58.1200697 L364.861514,53.6817255 C368.195932,56.9486536 372.782427,58.9578911 377.86899,58.9578911 C383.955687,58.9578911 390.958538,55.6085229 390.958538,47.5658214 C390.958538,40.867085 386.707966,38.1024662 381.205317,35.6733595 L375.534706,33.1618126 C368.77998,30.2284793 363.527365,26.1256471 363.527365,17.8356253 C363.527365,8.37035294 371.282225,1.75405664 381.287389,1.75405664 C386.791947,1.75405664 391.794528,3.59649673 395.462961,7.19701961 L392.876735,11.6353638 C389.79235,8.28599564 385.789903,6.61035294 381.205317,6.61035294 C373.116442,6.61035294 369.113995,11.7197211 369.113995,17.2470414 C369.113995,23.1117908 373.116442,25.8744924 377.952971,27.9680871 L383.621673,30.479634 C391.042519,33.6622048 396.547076,37.6845142 396.547076,46.8967146 C396.547076,58.2868671 387.040072,63.8985447 377.450995,63.8985447"
                      fill={color}
                    ></path>
                    <path
                      d="M26.6818356,4.2402963 L23.4714798,-0.000575163399 L20.2630327,4.2402963 C8.92181154,21.4951983 3.50123462,43.7731939 0.000763461538,63.624 L5.66946538,63.624 C11.7637971,23.6137168 23.1794558,8.84735512 23.1794558,8.84735512 L23.3474173,8.84735512 C28.5160519,17.5572462 32.4364269,27.8584227 35.4368308,38.2439564 L24.6548452,38.2439564 C24.6548452,38.2439564 22.4026337,39.246658 21.9617346,43.1865272 L36.6049269,43.1865272 C38.523124,50.2207756 39.8553644,57.1725839 40.8574077,63.624 L46.9441048,63.624 C43.441725,43.6888366 38.1891096,21.9975076 26.6818356,4.2402963"
                      fill={color}
                    ></path>
                    <path
                      d="M195.651716,4.2402963 L192.439451,-0.000575163399 L189.231004,4.2402963 C177.889783,21.4951983 172.471115,43.7731939 168.970644,63.624 L174.639346,63.624 C180.733677,23.6137168 192.149336,8.84735512 192.149336,8.84735512 L192.315389,8.84735512 C197.485932,17.5572462 201.404399,27.8584227 204.406711,38.2439564 L193.624725,38.2439564 C193.624725,38.2439564 191.372514,39.246658 190.929706,43.1865272 L205.572899,43.1865272 C207.491096,50.2207756 208.825245,57.1725839 209.827288,63.624 L215.912076,63.624 C212.409697,43.6888366 207.157081,21.9975076 195.651716,4.2402963"
                      fill={color}
                    ></path>
                    <path
                      d="M269.878691,33.8989804 C277.965658,32.307695 284.138244,27.1983268 284.138244,18.4040784 C284.138244,4.92033115 272.463009,2.5755817 261.623763,2.5755817 L248.950302,2.5755817 L248.950302,63.6234248 L254.536932,63.6234248 L254.536932,51.8325752 L254.536932,35.3234684 L254.536932,30.4652549 L254.536932,14.0673464 L254.536932,7.60059259 L261.623763,7.60059259 C269.378624,7.60059259 278.383653,8.85636601 278.383653,18.6571503 C278.383653,26.2857342 273.068052,29.4299608 266.301874,30.1105708 C266.301874,30.1105708 264.26534,31.0308322 263.927509,34.3169325"
                      fill={color}
                    ></path>
                    <polyline
                      fill={color}
                      points="267.999812 31.1186405 288.966375 63.6230414 282.774702 63.6230414 263.926745 34.316549"
                    ></polyline>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  )
}
