import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import Navbar from '@hoffman/shared/src/components/Navbar';

const LifestyleThirdImageExpandBlock = (props) => {
  const {dataJson} = useStaticQuery<GatsbyTypes.NavbarQuery>(graphql`
    query Navbar {
      dataJson(slug: {eq: "navbar"}) {
        bgImg {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        links {
          label
          url
        }
        cta {
          label
          url
        }
      }
    }
  `);

  const fluid = dataJson.bgImg?.childImageSharp?.fluid;
  const links = dataJson.links;
  const cta = dataJson.cta;
  return (
    <Navbar
      fluid={fluid}
      links={links}
      cta={cta}
      forceHidden={props.forceHidden}
      isHome={props.isHome}
    />
  );
};

export default LifestyleThirdImageExpandBlock;
