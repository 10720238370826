import React, {MouseEventHandler} from 'react';
import styled, {css, keyframes} from 'styled-components';
import {Logo} from '@hoffman/shared/src/components/Logo/logo';
import CloseIcon from '@hoffman/shared/src/components/CloseIcon';
import {ArrowCta} from '@hoffman/shared/src/components/Cta/cta';
import {RightArrow} from '../Arrow/arrow';
import {FluidObject} from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

interface NavSidebarMenuProps {
  show: boolean;
  handleShowSideMenu: () => void;
  bgImg: FluidObject;
  links: {
    label: string;
    url: string;
  }[];
  cta: {
    label: string;
    url: string;
  };
}

const StyledOuterContainer = styled.div`
  height: 100%;
  width: calc(100% - 82px);
  max-width: 263px;
  position: fixed;
  top: 0;
  right: ${
    ({show}: NavSidebarMenuProps) =>
      show ? `0px` : `calc(-100% - 82px)` /* 263 + padding */
  };
  bottom: 0;
  transition: right 1.4s ease-in-out;
  z-index: 10;
  box-shadow: ${({theme}) => theme.boxShadow};

  @media only screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    max-width: unset;
    width: 100%;
    transition: right 0.7s ease-in-out;
    right: ${
      ({show}: NavSidebarMenuProps) =>
        show ? `0px` : `calc(-100%)` /* 263 + padding */
    };
`;

const StyledContainer = styled.div`
  padding: 41px;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 99%;
  font-size: ${({theme}) => theme.fontSizes[0]}px;
  font-family: ${({theme}) => theme.fontFaces.default};
  color: ${({theme}) => theme.colors.text};

  @media only screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    padding: 19px;
  }
`;

const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
  background-size: cover;
  height: 100%;

  * {
    margin-top: 0;
  }
`;

const StyledTop = styled.div`
  display: flex;
  justify-content: space-between;
  top: 0;
  width: 100%;
`;

const StyledLogoContainer = styled.a`
  cursor: pointer;

  > svg path,
  > svg g,
  > svg polygon,
  > svg polyline {
    fill: ${({theme}) => theme.colors.text};
  }

  svg line {
    stroke: ${({theme}) => theme.colors.text};
  }

  @media only screen and (min-width: ${({theme}) => theme.breakpx[0] + 2}px) {
    visibility: hidden;
  }
`;

const StyledClose = styled.button`
  text-transform: uppercase;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  letter-spacing: ${({theme}) =>
    theme.letterSpacings[4] + theme.letterSpacings[5]}px;
`;

const StyledCloseSpan = styled.span`
  color: ${({theme}) => theme.colors.text};
  margin-right: ${({theme}) => theme.spaces[2]}px;
`;

const StyledLinkContainer = styled.ul`
  font-size: ${({theme}) => theme.fontSizes[5] - 5}px;
  font-family: ${({theme}) => theme.fontFaces.heading};
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledLinkLi = styled.li`
  margin-bottom: ${({theme}) => theme.spaces[4]}px;
  position: relative;

  &.link-1,
  &.link-2,
  &.link-3 {
    opacity: 0;
    transition: opacity 0s 0.7s;
  }

  ${({show}) =>
    show &&
    css`
      &.link-1 {
        opacity: 1;
        transition: opacity 0.5s linear 0.7s;
      }
      &.link-2 {
        opacity: 1;
        transition: opacity 0.7s linear 0.7s;
      }
      &.link-3 {
        opacity: 1;
        transition: opacity 0.9s linear 0.7s;
      }
    `}
  @media only screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    &.link-1,
    &.link-2,
    &.link-3 {
      opacity: 0;
      transition: opacity 0s 0.7s;
    }

    ${({show}) =>
      show &&
      css`
        &.link-1 {
          opacity: 1;
          transition: opacity 0.5s linear 0.7s;
        }
        &.link-2 {
          opacity: 1;
          transition: opacity 0.7s linear 0.7s;
        }
        &.link-3 {
          opacity: 1;
          transition: opacity 0.9s linear 0.7s;
        }
      `}
  }
`;

const StyledLi = styled.li`
  a {
    opacity: 0;
    transition: opacity 0s 0.7s;
  }
  ${({show}) =>
    show &&
    css`
      a {
        opacity: 1;

        transition: opacity 1.4s linear 1.4s;
      }
    `}
  @media only screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    a {
      opacity: 0;
      transition: opacity 0s 0.7s;
    }
    ${({show}) =>
      show &&
      css`
        a {
          opacity: 1;

          transition: opacity 1.1s linear 0.7s;
        }
      `}
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({theme}) => theme.colors.textAlt};
  position: relative;
  margin-bottom: 2px; // underline width

  @media only screen and (min-width: ${({theme}) => theme.breakpx[0] + 1}px) {
    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      content: ' ';
      width: 0;
      transition: width 0.4s ease-in;
      height: 2px;
      background-color: ${({theme}) => theme.colors.text};
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
`;

const NavSidebarMenu = ({
  show,
  handleShowSideMenu,
  bgImg,
  links,
  cta,
}: NavSidebarMenuProps) => {
  return (
    <StyledOuterContainer show={show}>
      <StyledBackgroundSection fluid={bgImg} className="background-section">
        <StyledContainer>
          <StyledTop>
            <StyledLogoContainer href="/">
              <Logo color="#414448" size="119" />
            </StyledLogoContainer>
            <StyledClose onClick={handleShowSideMenu}>
              <StyledCloseSpan>Close</StyledCloseSpan>{' '}
              <CloseIcon size="18" color="#414448" />
            </StyledClose>
          </StyledTop>
          <StyledLinkContainer>
            {links &&
              links.map((link, i) => {
                return (
                  <StyledLinkLi
                    show={show}
                    key={link.label}
                    className={`link-${i + 1}`}
                  >
                    <StyledLink href={link.url}>{link.label}</StyledLink>
                  </StyledLinkLi>
                );
              })}
            {cta && (
              <StyledLi show={show}>
                <ArrowCta color={'#414448'} link={cta.url} text={cta.label} />
              </StyledLi>
            )}
          </StyledLinkContainer>
        </StyledContainer>
      </StyledBackgroundSection>
    </StyledOuterContainer>
  );
};

export default NavSidebarMenu;
